import 'bootstrap/js/dist/collapse.js';

(function($) {

    $('.subcategories__link--js').click(function(e){
        e.preventDefault();
        let target =  $(this).data('target');
        if(!$(this).parent().hasClass('subcategories__list-item--active')){
            $(target).find('.subcategories__list-item--active').removeClass('subcategories__list-item--active');
            $(this).parent().addClass('subcategories__list-item--active');
            $(target).find('.posts-loading').removeClass('d-none');
            $.ajax({
                type: 'POST',
                url: mp_ajax.ajaxurl,
                dataType: "html", // add data type
                data: { action : 'get_ajax_posts', 'term_id': $(this).data('id') },
                success: function( response ) {
                    $(target).find('.posts-loading').addClass('d-none');
                    $(target).find('.posts-wrapper').html( response );
                }
            });
        }
    });

}(jQuery));
