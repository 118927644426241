(function($) {
    //add Element Actions on elementor init
    $(window).on('elementor/frontend/init', function() {


        elementorFrontend.hooks.addAction( 'frontend/element_ready/form.default', function($scope){
            $scope.find('input, textarea').focus(function(e) {
                var name = $(this).attr('id');
                if ($(this).val()) {
                    $('label[for="' + name + '"]').addClass('active');
                }
                if ($('label[for="' + name + '"]').length > 0) {
                    $('label[for="' + name + '"]').addClass('active');
                }
            });

            $scope.find('input, textarea').blur(function(e) {
                var name = $(this).attr('id');
                if ($('label[for="' + name + '"]').length > 0) {
                    $('label[for="' + name + '"]').removeClass('active');
                }
                if ($(this).val()) {
                    $('label[for="' + name + '"]').addClass('active');
                }
            });
        });

        elementorFrontend.hooks.addAction( 'frontend/element_ready/n1-home-swiper.default', function($scope){
            var thisSwipterId = $scope.find('.swiper-container').attr('id');
            var swiper = new Swiper('#'+thisSwipterId, {
                slidesPerView: 1,
                spaceBetween: 24,
                centeredSlides: false,
                loop: false,
                pagination: {
                    el: '#'+thisSwipterId + ' .swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '#'+thisSwipterId + ' .swiper-button-next',
                    prevEl: '#'+thisSwipterId + ' .swiper-button-prev',
                },
            });
        });

    });

    // MOBILE MENU

    $(document).ready(function() {
        jQuery( document ).on( 'elementor/popup/show', () => {
            // === Moblie Menu Layout ===
            var $Menu = $('.mobile-menu');
            var $ActiveLink = $Menu.find('li').has('ul').children('a');
            var $MenuChildUl = $Menu.find('li ul');
            // Active menu when click link on li
            $ActiveLink.off('click').on('click', function() {
                $(this).closest('li').addClass('active');
                $(this).closest('li').siblings().removeClass('active');
                $(this).closest('li').siblings().each(function(index, elm){
                    console.log(elm);
                    $(elm).find('.active').removeClass('active');
                });
                $('.mobile-menu').addClass('active');
                $('.ul-node.active').scrollTop(0);
            });

            // Add event to back button
            $MenuChildUl.find('.mobile-menu__back-btn').on('click', function(event) {
                event.preventDefault();
                $(this).closest('li.active').removeClass('active');
                $(this).closest('ul.active').removeClass('active');
                $('.mobile-menu.active').removeClass('active');
            });

            // Add event to back button
            $MenuChildUl.find('.mobile-menu__breadcrumb').on('click', function(event) {
                event.preventDefault();
                let newIndex = $(this).data('index');
                $Menu.find('.ul-node.active').each(function(){
                    if($(this).data('index') === newIndex){
                        $(this).find('.active').removeClass('active');
                    }
                });
            });
        } );
    });

    $('#search-toggle').click(function() {
        setTimeout( function () { $(".elementor-search-form__input").focus(); }, 500);
    });
}(jQuery));
